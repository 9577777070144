import PackerLayout from "Layout"
import { Navigate } from "react-router-dom"

import Pages from "./Pages"

const AdminRoutes = [
  {
    path: "/",
    element: <PackerLayout />,
    children: [
      {
        index: true,
        element: <Pages.Lists />,
      },
      {
        path: "list/:id",
        element: <Pages.CancelingOrders />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
    children: [],
  },
]

if (process.env.REACT_APP_MODE === "development") {
  AdminRoutes.push()
}

export default AdminRoutes
