import { Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import "./styles.scss"
import "./packer-modal.scss"

function PackerLayout() {
  return (
    <div className="packer packer-layout">
      <div className="packer-wrapper">
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}

export default PackerLayout
