const { lazy } = require("react")

const Pages = {
  Signin: lazy(() => import("Pages/Auth/Signin")),
  RestorePassword: lazy(() => import("Pages/Auth/RestorePassword")),

  Lists: lazy(() => import("Pages/Lists")),
  CancelingOrders: lazy(() => import("Pages/CancelingOrders")),
}

export default Pages
